<template>
  <div id="webcaller">
    <button
        v-show="!connected"
        class="webcaller-icon"
        id="webcaller-icon"
        @click="icon.isPaused ? onClickStartCall($event) : onClickEndCall($event)"
        :style="{'--icon-color': this.iconColor, width:iconSize+'px',height:iconSize+'px',backgroundColor:iconBg}"
    ></button>
    <button
        v-show="connected"
        class="webcaller-icon active"
        id="webcaller-icon-active"
        @click="onClickEndCall"
        :style="{'--icon-color-active': this.iconColorConnected, width:iconSize+'px',height:iconSize+'px',backgroundColor:iconBg}"
    ></button>
    <transition name="fade">
      <div v-if="showInput" id="webcaller-name" :style="{background:iconBg,'--icon-size': this.iconSize+'px'}">
        <input type="text" v-model="name" ref="input" :placeholder="t('your_name')" v-on:keypress="isLetter($event)">
        <button :style="{'--msg-bg':this.msgBg}" @click="onClickStartCall($event)">OK</button>
      </div>
      >
    </transition>
    <transition name="fade">
      <div v-if="message" :style="messageCss" id="webcaller-msg">{{ message }}</div>
    </transition>
    <audio id="call-audio"/>
  </div>
</template>

<script>
import 'webrtc-adapter'

import lottieWeb from "lottie-web"
import microphoneIcon from "./assets/microphone.json"
import phoneIcon from "./assets/phone.json"

export default {
  name: 'Webcaller',
  props: {
    active: {
      type: Boolean,
      default: true
    },
    lang: {
      type: String,
      default: 'ru'
    },
    socketUri: {
      type: String,
      default: 'wss://demoaccount.s02.radio-tochka.com:4660/mount'
    },
    stun: {
      type: String,
      default: 'stun.l.google.com:19302'
    },
    iconSize: {
      type: String,
      default: '60',
    },
    iconBg: {
      type: String,
      default: '#f9f9f9',
    },
    iconColor: {
      type: String,
      default: '#1976d2',//'#1976d2',//#5aa213',//
    },
    iconColorConnected: {
      type: String,
      default: '#5aa213',//'#1976d2',//#5aa213',//
    },
    msgBg: {
      type: String,
      default: '#fcfcfc',//'#fafde0'
    },
    errBg: {
      type: String,
      default: '#fef1f1'
    }
  },
  data: function () {
    return {
      mutableSocketUri: this.socketUri,
      id: null,
      name: '',
      showInput: false,
      message: '',
      messageType: 'msg',
      connected: false,
      connecting: false,
      icon: null,
      messages: {
        'en': {
          'waiting': 'waiting...',
          'socket_failed': 'Socket connection failed',
          'your_name': 'Your name',
          'enter_name': 'Enter name first',
          'connected': 'Connected',
          'disconnected': 'Connection lost',
          'connection_failed': 'Connection failed',
          'connection_closed': 'end of call',
          'call_denied': 'Call denied. Please try later'
        },
        'ru': {
          'waiting': 'ожидайте...',
          'socket_failed': 'Соединение к сокету не удалось',
          'your_name': 'Ваше имя',
          'enter_name': 'Представьтесь',
          'connected': 'Соединение установлено',
          'disconnected': 'Соединение потеряно',
          'connection_failed': 'Ошибка подключения',
          'connection_closed': 'звонок окончен',
          'call_denied': 'Звонок отклонен. Пожалуйста, попробуйте позднее'
        }
      }
    }
  },
  created() {
    this.lifetime = 2000 * 60
    this.maxSocketTry = 3
    this.socketTry = 0

    this.debuger = true
    this.mediaConstraints = {audio: true, video: false}
    this.socket = null
    this.candidateQueue = {}
    this.peerConnection = null
    this.stream = null

    this.micIcon = null
    this.msgTimer = null

    this.b64DecodeUnicode = function (str) {
      this.debug('STR:',str)
      return decodeURIComponent(atob(str).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));
    }

    if(window.location.hash) {
      this.mutableSocketUri = this.b64DecodeUnicode(window.location.hash.replace('#',''))
      this.debug('Socket by hash:',this.mutableSocketUri)
    }

  },

  computed: {
    messageCss() {
      return {
        '--msg-bg': this.messageType === 'err' ? this.errBg : this.msgBg
      }
    }
  },

  watch: {
    connected: function () {
      if (this.connected) {
        this.$nextTick(function () {
          this.icon.stop();
          this.micIcon.play();
        });
      } else {
        this.$nextTick(function () {
          this.micIcon.stop();
        });
      }
    },
    showInput: function (val){
      if(val) this.$nextTick(() => this.$refs.input.focus())
    },
  },

  mounted() {

    let phoneURL = URL.createObjectURL(new Blob([JSON.stringify(phoneIcon)], {type: 'application/json'}));
    this.icon = lottieWeb.loadAnimation({
      container: document.getElementById('webcaller-icon'),
      path: phoneURL,
      renderer: 'svg',
      loop: true,
      autoplay: false,
      name: "Phone",
    });

    let microphoneURL = URL.createObjectURL(new Blob([JSON.stringify(microphoneIcon)], {type: 'application/json'}));
    this.micIcon = lottieWeb.loadAnimation({
      container: document.getElementById('webcaller-icon-active'),
      path: microphoneURL,
      renderer: 'svg',
      loop: true,
      autoplay: false,
      name: "Microphone",
    });

  },
  methods: {

    t(prop) {
      if (typeof this.messages[this.lang] !== 'undefined' && typeof this.messages[this.lang][prop] !== 'undefined') {
        return this.messages[this.lang][prop]
      } else return this.messages['en'][prop]
    },

    isLetter(e) {
      if (e.keyCode === 13) {
        this.onClickStartCall(e)
      }
      let char = String.fromCharCode(e.keyCode)
      if (/[^&/\\#,+()$~%.'":;*?<>{}]+$/.test(char)) return true
      else e.preventDefault()
    },

    addMessage(message, lifetime = 0, type = 'msg') {
      this.messageType = type
      this.message = message

      clearTimeout(this.msgTimer)

      if (lifetime > 0) {
        this.msgTimer = setTimeout(() => {
          this.message = ''
        }, lifetime)
      }

      this.debug('Add message:', message);
    },

    generateId() {
      this.id = parseInt(Math.ceil(Math.random() * Date.now()).toPrecision(16).toString().replace(".", ""))
    },

    createConnection() {
      this.generateId()
      let ICE_config = {
        'iceServers': [
          {
            urls: 'stun:stun.streaming.center'
          },
          {
            urls: "turn:turn.streaming.center",
            username: "scwebrtc",
            credential: "Uvh68RHaqKVCyZn2",
          }
        ]
      }
      this.peerConnection = new RTCPeerConnection(ICE_config);

      this.peerConnection.onicecandidate = this.onIceCandidate;

      this.peerConnection.ontrack = (e) => {
        if (e.streams && e.streams[0]) {
          this.stream = e.streams[0];
        } else {
          this.stream = new MediaStream(e.track);
        }
        this.debug('Add remote track:', this.stream)

        let audio = document.getElementById('call-audio')
        audio.srcObject = this.stream;
        audio.volume = 1;
        audio.play();
      }

      this.peerConnection.onnegotiationneeded = (e) => {
        this.debug('onnegotiationneeded:',e)
      }

      this.peerConnection.oniceconnectionstatechange = () => {
        switch (this.peerConnection.iceConnectionState){
          case "connected":
            this.connected = true
            this.connecting = false
            this.addMessage(this.t('connected'), 5000)
            break;
          case "completed":
            this.connected = true
            this.connecting = false
            break;
          case "disconnected":
            this.endCall()
            this.addMessage(this.t('disconnected'), 5000, 'err')
            break;
          case "closed":
            this.endCall()
            this.addMessage(this.t('connection_closed'), 5000)
            break;
          case "failed":
            this.endCall()
            this.addMessage(this.t('connection_failed'), 5000, 'err')
            break;
        }
        this.debug('ICE status:' + this.peerConnection.iceConnectionState)
      }
    },

    stopConnection() {
      if (this.peerConnection) this.peerConnection.close()
    },

    sendToServer: async function (msg) {
      // if connection is lost
      if (!this.socket || this.socket.readyState === 3) {
        if (this.socket) this.socket.close();
        this.socketConnect()
      }
      const opened = await this.connection(this.socket)
      if (!opened && !this.icon.isPaused) { // TODO or mic icon
        this.endCall()
        this.addMessage(this.t('socket_failed'), 5000, 'err')
        return
      }

      let msgJSON = JSON.stringify(msg);
      this.socket.send(msgJSON);

      this.debug('Send to server:', msgJSON);
    },

    startCall: function () {
      this.icon.play()
      this.connecting = true

      this.addMessage(this.t('waiting'))

      navigator.mediaDevices.getUserMedia(this.mediaConstraints).then((stream) => {

        this.socketConnect()

        this.connection(this.socket).then((opened) => {
          this.debug('Socket:',this.socket)
          if (!opened && !this.icon.isPaused) { // TODO or mic icon
            this.endCall()
            this.addMessage(this.t('socket_failed'), 5000, 'err')
            return
          }

          this.createConnection()

          for (const track of stream.getAudioTracks()) {
            this.peerConnection.addTrack(track, stream);

            this.debug('Add local track:', track)
          }
          //this.peerConnection.addStream(stream)

          this.peerConnection.createOffer().then((offer) => {
            return this.peerConnection.setLocalDescription(offer)
          })
              .then(() => {
                this.sendToServer({
                  id: this.id,
                  name: this.name,
                  type: 'call-offer',
                  offer: this.peerConnection.localDescription
                });
              })
              .catch(function error(e) {
                console.error("Error: ", e)
              });

          this.debug('Send offer:', this.peerConnection.localDescription)

        })
      })
    },

    endCall() {
      this.stopConnection()
      this.icon.stop()
      this.connected = false
      this.connecting = false

      // stop audio
      let audio = document.getElementById('call-audio')
      audio.pause();

      // remove stream tracks
      if (this.stream) {
        this.stream.getAudioTracks().forEach((track) => {
          track.stop();
          this.stream.removeTrack(track)
        })
      }

      this.socket.close()
    },

    onClickStartCall(event) {
      if (this.name) {
        this.showInput = false
        this.startCall()
      } else {
        this.showInput = true
        this.addMessage(this.t('enter_name'), 3000)
      }
      this.debug('Click start call:', event)
    },

    onClickEndCall(event) {
      this.endCall()
      this.message = ''

      this.sendToServer({
        id: this.id,
        receiver: 'webcaster',
        type: 'call-closed-by'
      })

      this.debug('Click end call:', event)
    },

    onIceCandidate(event) {
      this.sendToServer({
        id: this.id,
        receiver: 'webcaster',
        type: 'candidate',
        candidate: event.candidate
      })

      this.debug('New ICE candidate:', event)
    },

    onCallAnswer(data) {
      if (data.receiver != this.id) return
      this.peerConnection.setRemoteDescription(new RTCSessionDescription(data.answer));

      this.debug('Answer arrived: ', data)
    },

    onCallDenied(data) {
      if (data.receiver != this.id) return

      this.addMessage(this.t('call_denied'), 7000)
      this.endCall()

      this.debug('Call denied: ', data)
    },

    onCallClosed(data) {
      if (data.receiver != this.id) return

      this.addMessage(this.t('connection_closed'), 5000)
      this.endCall()

      this.debug('Call closed: ', data)
    },

    onCandidate(data) {
      this.debug('Candidate arrived:', data)

      if (data.receiver != this.id) return
      if(data.candidate)
        this.peerConnection.addIceCandidate(new RTCIceCandidate(data.candidate));
      else
       this.peerConnection.addIceCandidate(null);
    },

    socketConnect: function () {

      this.socket = new WebSocket(this.mutableSocketUri)

      this.socket.onopen = () => {
        this.socketTry = 0
        this.debug('WS connected!')
      }

      this.socket.onmessage = (message) => {
        let data = JSON.parse(message.data)
        this.debug('Message:',data)
        switch (data.type) {
          case "call-answer":
            this.onCallAnswer(data)
            break;
          case "call-denied":
            this.onCallDenied(data)
            break;
          case "call-closed":
            this.onCallClosed(data)
            break;
          case "candidate":
            this.onCandidate(data)
            break;
          default:
            break;
        }
      }

      this.socket.onerror = () => {
        this.debug('WS error!')
      }

      this.socket.onclose = () => {
        this.socket = null
        if(this.active){
          this.socketTry ++;
          //if( (this.connecting || this.connected) && this.maxSocketTry >= this.socketTry) setTimeout(this.socketConnect, 50)
        }

        this.debug('WS Close!')

      }
    },

    connection: async function (socket, timeout = 10000) {
      const isOpened = () => (socket.readyState === WebSocket.OPEN)

      if (socket.readyState !== WebSocket.CONNECTING) {
        return isOpened()
      } else {
        const intrasleep = 100
        const ttl = timeout / intrasleep // time to loop
        let loop = 0
        while (socket.readyState === WebSocket.CONNECTING && loop < ttl) {
          await new Promise(resolve => setTimeout(resolve, intrasleep))
          loop++
        }
        return isOpened()
      }
    },

    debug(msg, obj = '') {
      if (this.debuger === true) {
        console.log('🚩️ ' + msg, obj)
      }
    }
  }
}
</script>

<style>
body > #webcaller{
  margin-top: 60px;
}
#webcaller {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  position: relative;
}

#webcaller-name {
  position: absolute;
  left: 0;
  right: 0px;
  /*right: calc(var(--icon-size) + 10px);*/
  top: 70px;
  top: calc(var(--icon-size) + 40px);
  /*transform: translateX(-50%) translateY(-50%);*/
  width: fit-content;
  margin: auto;
  padding: 5px;
  border-radius: 5px;
}

#webcaller-name input {
  padding: 5px;
  border: 0;
  border-radius: 5px;
  outline: none;
  padding-right:34px;
}
#webcaller-name button {
  background: #fafde0;
  background: var(--msg-bg);
  border:0;
  position:absolute;
  top:0;
  bottom:0;
  margin:auto;
  right: 6px;
  height: 22px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  cursor: pointer;
}

.webcaller-icon {
  border-radius: 100%;
  border: 0;
  cursor: pointer;
}

/*
#webcaller-icon svg path[stroke="rgb(90,162,19)"],
#webcaller-icon svg path[stroke="rgb(18,19,48)"]
 */
.webcaller-icon svg path[stroke="rgb(25,118,210)"] {
  stroke: #1976d2;
  stroke: var(--icon-color);
}

.webcaller-icon svg path[fill="rgb(25,118,210)"] {
  fill: #1976d2;
  fill: var(--icon-color);
}

.webcaller-icon.active svg path[stroke="rgb(25,118,210)"] {
  stroke: #5aa213;
  stroke: var(--icon-color-active);
}

.webcaller-icon.active svg path[fill="rgb(25,118,210)"] {
  fill: #5aa213;
  fill: var(--icon-color-active);
}

#webcaller-msg {
  position: absolute;
  left: 0;
  right: 0;
  margin: 5px auto 0;
  padding: 5px 10px;
  background: #fafde0;
  background: var(--msg-bg);
  width: fit-content;
  display: table;
  border-radius: 5px;
  font-size: 14px;
  letter-spacing: 0.3px;
}

#webcaller-msg::before {
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 4px 4px 4px;
  border-color: transparent transparent #fafde0 transparent;
  border-color: transparent transparent var(--msg-bg) transparent;
  top: -3px;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */
{
  opacity: 0;
}
</style>
